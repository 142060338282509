import React, { useState, useEffect } from "react"
import FetchYouTubeVideoDetails from "./fetch/FetchYouTubeVideoDetails"

const VideoDuration = ({ videoId, apiKey }) => {
  const [duration, setDuration] = useState("")

  useEffect(() => {
    FetchYouTubeVideoDetails(videoId, apiKey)
      .then(details => {
        const durationMatch = details.contentDetails.duration.match(
          /PT(\d+)M(\d+)S/
        )
        if (durationMatch) {
          const minutes = parseInt(durationMatch[1])
          const seconds = parseInt(durationMatch[2])
          setDuration(`${minutes}min ${seconds}s`)
        }
      })
      .catch(error => console.error(error))
  }, [videoId, apiKey])

  return <p className="font-avenir-light text-16 text-gray-500">{duration}</p>
}


export default VideoDuration
