import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { navigate } from "gatsby";

function DiscoverPodcast({ t, i18n }) {
  useEffect(() => {
    if (typeof window !== "undefined" && i18n.language === "en") {
      navigate("/");
    }
  }, [i18n.language]);

  return (
    <section className="p-2 tablet:p-20 text-left bigdesktop:pl-24">
      <div className="flex flex-col pl-2">
        <h4 className="text-blue-100 font-avenir-black text-12 pl-2">X MEDIA</h4>
        <p className="font-bold mt-2 mb-4 tablet:w-1/3 font-avenir-black text-gray-600 text-48 leading-tight">
          {t("podcast.title")}
        </p>
        <p className="text-16 font-avenir-light text-gray-500 tablet:w-5/12">
          {t("podcast.desc")}
        </p>
      </div>
    </section>
  );
}

export default withNamespaces()(DiscoverPodcast);
