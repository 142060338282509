const FetchYouTubeVideoDetails = async (videoId, apiKey) => {
  try {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet,contentDetails`
    )
    const data = await response.json()
    return data.items[0]
  } catch (error) {
    throw new Error("Failed to fetch video details")
  }
}


export default FetchYouTubeVideoDetails