import axios from 'axios';
import Swal from 'sweetalert2';
const fetchYouTubePlaylist = async (playlistId, apiKey) => {
  const apiUrl = `https://www.googleapis.com/youtube/v3/playlistItems`;
  const params = {
    part: 'snippet',
    playlistId: playlistId,
    key: apiKey,
    maxResults: 10, 
  };

  try {
    const response = await axios.get(apiUrl, { params });
    return response.data.items;
  } catch (error) {
    Swal.fire(
      "error",
      "Error Fetching YouTube Playlist"
    )
    return [];
  }
};

export default fetchYouTubePlaylist;