import React from "react"
import recordIcon from "../../images/xMmediaPodcast/icons/record.png"
import timerIcon from "../../images/xMmediaPodcast/icons/timer.png"
import VideoDuration from "./VideoDuration"
function PodcastCard({ item, index, apiKey }) {
  const limitDescriptionText = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "..."
    }
    return text
  }

  const podcaster = [
    "Sebastián Jiménez",
    "Ariana Jiménez",
    "Juan Nieto",
    "Diana Pulido",
    "Cristian Abril",
    "Julian Bonilla",
    "Hernán Fagua",
  ]

  const colorsBg = [
    "rgba(109, 78, 246, 0.1)",
    "rgba(0, 172, 238, 0.1)",
    "rgba(247, 209, 78, 0.1)",
    "rgba(133, 210, 93, 0.1)",
    "rgba(204, 50, 113, 0.1)",
  ]

  const podcasteIncludeName = text => {
    const nameInclude = podcaster.filter(name => text.includes(name))
    if (nameInclude.length > 0) {
      return nameInclude[0]
    }
  }

  return (
    <div
      className={`py-10 px-8 tablet:px-16 tablet:flex bigdesktop:py-16 items-center gap-10 rounded-sm text-left`}
      style={{ backgroundColor: colorsBg[(index + 1) % 5] }}
    >
      <img
        src={item.snippet.thumbnails.medium.url}
        alt="item.snippet.thumbnails.default.url"
        className="mx-auto rounded-xxs"
      />
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center mt-5 tablet:mt-0">
          <img src={recordIcon} alt="record" className="" />
          <span className="font-avenir-light text-16 text-gray-500">
            {podcasteIncludeName(item.snippet.title)}
          </span>
        </div>
        <h1 className="font-avenir-black text-gray-600 text-36 bigdesktop:w-11/12 leading-tight">
          {`Ep ${index + 1}: ${item.snippet.title}`}
        </h1>
        <p className="font-avenir-light text-16 text-gray-600 tablet:w-5/6">
          {limitDescriptionText(item.snippet.description, 200)}
        </p>
        <div className="tablet:flex gap-4 tablet:mt-3 ">
          <div className="bg-blue-100 w-full text-center tablet:w-fit my-5 tablet:my-0 text-white text-16 rounded-lg py-2 border-2 border-blue-100 px-10 font-sofiaPro-bold ">
            <a
              href={`https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`}
              target="_blank"
              rel="noreferrer"
            >
              PLAY EPISODIO
            </a>
          </div>
          <div className="flex gap-2 items-center">
            <img src={timerIcon} alt="timer" className="" />
            <VideoDuration
              videoId={item.snippet.resourceId.videoId}
              apiKey={apiKey}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PodcastCard
