import React from "react"
import i18n from "i18next"

function TextBannerPodcast() {
  return (
    <div className="text-left  pl-5 tablet:pl-20 bigdesktop:pl-24 text-white mb-32 tablet:mb-0">
      <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
        <span className="textOurExperience2 flex">
          <p className="subtitleTextTalentX">X&nbsp;</p>
          <p className="subtitleTextTalent">Media</p>
        </span>
        <span className="textBanners">
          <p className="colorTextExperience2">Podcast</p>
          <p className="colorTextExperience2">&nbsp;</p>
        </span>
      </p>
    </div>
  )
}

export default TextBannerPodcast
