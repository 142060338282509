import React, { useState, useEffect, useContext } from "react"
import FetchYTPlaylist from "./fetch/FetchYTPlaylist"
import PodcastCard from "./PodcastCard"
import i18n from "i18next"
import Swal from "sweetalert2"

function PodcastSection() {
  const apiKey = "AIzaSyCzgW_bM6--mplRpQz7_5OxwS3bQcodo_s"
  const playlistId = "PLD9URRsIxDt94_egVYMIrA2XwO9po528-"
  const [playlistItems, setPlaylistItems] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItemss, setFilteredItems] = useState([])
  useEffect(() => {
    FetchYTPlaylist(playlistId, apiKey)
      .then(items => setPlaylistItems(items))
      .catch(error => {
        Swal.fire(
          "error",
          "Opss, No Possible Fetch Content From Youtube"
        )
      })
  }, [])


  const filteredItems = playlistItems.filter(
    item =>
      item.snippet.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.snippet.description.toLowerCase().includes(searchTerm.toLowerCase())
  )
  
  return (
    <section className="mb-8 desktop:mb-20">
      <div className="flex flex-col tablet:flex-row tablet:gap-48 px-4 desktop:px-20 mb-24 items-center  justify-between ">
        <div className="w-full desktop:w-2/5 mt-10 mb-6 tablet:mb-0 desktop:mt-0 flex desktop:space-x-8  bigdesktop:pl-3">
          <button
            onClick={() => setSelectedCategory("all")}
            className={`px-4 py-2 rounded-lg font-avenir-light desktop:font-avenir-medium text-16 ${
              selectedCategory === "all"
                ? "bg-blue-100 text-white"
                : "bg-white text-gray-600"
            }`}
          >
            {i18n.t("podcast.btnAllPod")}
          </button>

          <button
            onClick={() => setSelectedCategory("diasExpertos")}
            className={`px-4 py-2 rounded-lg font-avenir-light desktop:font-avenir-medium text-16 ${
              selectedCategory === "diasExpertos"
                ? "bg-blue-100 text-white"
                : "bg-white text-gray-600"
            }`}
          >
            {i18n.t("podcast.btnExperts")}
          </button>
        </div>
        <div className="w-full flex flex-col gap-4 tablet:flex-row desktop:w-3/5 bigdesktop:w-2/5 bigdesktop:pl-20 bigdesktop:pr-10 desktop:pr-6">
          <input
            type="text"
            placeholder={i18n.language === "en" ? "Search podcast..." : "Buscar podcast..."}	
            className="w-full flex-1 py-2 px-3 rounded-xxs border border-gray-100"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        <button className="px-10 py-2 w-full desktop:w-fit font-avenir-medium text-20 bg-blue-100 text-white rounded-lg">
          {i18n.language === "en" ? "Search" : "Buscar"}	
        </button>
        </div>
      </div>

      {filteredItems.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-20 text-gray-600">
            {i18n.language === "en" ? "No content" : "Sin contenido"}
          </p>
        </div>
      ) : (
        <div className="flex flex-col pb-24 gap-10 mx-auto p-2 tablet:p-0 justify-center tablet:w-4/5 bigdesktop:w-2/3 ">
          {filteredItems.map((item, index) => (
            <PodcastCard
              key={index}
              item={item}
              index={index}
              apiKey={apiKey}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export default PodcastSection
