import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerXMmediaPodcast from "../components/xMmediaPodcast/BannerXMmediaPodcast"
import imageBanner from "../../src/images/xMmediaPodcast/BannerPodcast.png"
import TextComponent from "../components/xMmediaPodcast/TextBannerPodcast"
import DiscoverPodcast from "../components/xMmediaPodcast/DiscoverPodcast"
import PodcastSection from "../components/xMmediaPodcast/PodcastSection"
import { withNamespaces } from "react-i18next";
function xMmediaPodcast() {
  return (
    <Layout>
      <SEO title="X Media Blog Podcast | Exsis Digital Angels" description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
      <BannerXMmediaPodcast image={imageBanner} TextComponent={TextComponent} />
      <DiscoverPodcast />
      <PodcastSection />
    </Layout>
  )
}

export default withNamespaces()(xMmediaPodcast)